.gallery {
  margin: 0 -15px;

  .gallery-item {
    float: left;
    padding: 0 15px 30px;

    img {
      width: 100%;
      height: auto;
    }

    figcaption .caption {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  .clearfix {
    clear: both;
  }
}

#zoombox-overlay {
  background: rgba(255,255,255,0.5);
}
